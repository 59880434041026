import React from "react";
import look from "./Assets/look.png";
import styles from "./heroSectionTwo.module.css";
import playicon from "./Assets/playicon.png";
import arrow from "./Assets/arrow.svg";

function HeroSectionTwo({ handleClick }) {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          <img src={look} alt="side" className={styles.image} />
        </div>

        <div className={styles.contentContainer}>
          <span className={styles.title}>
            Your Gateway to Excellence in Higher Education
          </span>
          <div className={styles.description}>
            Unlock unparalleled opportunities with our expert consulting
            services. We offer end-to-end support, tailored advice, and access
            to top universities, paving the way for your academic and
            professional success.
          </div>
          <div className={styles.buttonCover}>
            <button>Get Started</button>
            <button>
              Learn More <img src={playicon} alt="Play icon" />
            </button>
          </div>
        </div>
      </div>
      <div className={styles.footer} onClick={handleClick}>
        <img src={arrow} alt="Arrow icon" className={styles.arrow} />
      </div>
    </>
  );
}

export default HeroSectionTwo;
