import React from "react";
import ravi from "./Assets/ravi.png";
import priya from "./Assets/priya.png";
import sneha from "./Assets/sneha.png";
import comma from "./Assets/comma.png";
import { Rating } from "@mui/material";

function SuccessStories() {
  const reviewsDetails = [
    {
      name: "Ravi Kumar",
      clgDetails: "B.Tech JNTU",
      review:
        "YSR's support was fantastic! I got into my dream engineering college thanks to their expert guidance. The website made everything easy to navigate.",
      image: ravi,
      rating: 3.5
    },
    {
      name: "Priya Reddy",
      clgDetails: "B.Tech JNTU",
      review:
        "YSR Educational Services guided me to secure a top medical college seat. Their expert advice and comprehensive website were invaluable. Highly recommended!",
      image: priya,
      rating: 4.5
    },
    {
      name: "Sneha Sharma",
      clgDetails: "B.Tech JNTU",
      review:
        "YSR Educational Services was crucial in my MBA admission at GITAM Hyderabad, with personalized guidance and efficient website navigation. Grateful for their support!",
      image: sneha,
      rating: 4
    }
  ];

  return (
    <div>
      <div style={{ marginLeft: "41px" }}>
        <button
          style={{
            width: "162px",
            height: "43px",
            borderRadius: "24px",
            marginTop: "24px",
            left: "16px",
            gap: "0px",
            border: "0px",
            fontFamily: "Poppins",
            fontSize: "18px",
            fontWeight: "700",
            lineHeight: "27px",
            textAlign: "center",
            color: "rgba(5, 84, 242, 1)",
            background: "rgba(232, 246, 255, 1)"
          }}
        >
          Testimonial
        </button>
        <div
          style={{
            width: "52rem",
            height: "106px",
            marginTop: "16",
            gap: "0px",
            opacity: "0px"
          }}
        >
          <span
            style={{
              fontFamily: "Bitter",
              fontSize: "33px",
              fontWeight: "700",
              lineHeight: "40px",
              textAlign: "left",
              marginBottom: "0px"
            }}
          >
            Success Stories: Students Achieving Their Dreams with YSR
            Educational Services
          </span>
        </div>
        <div style={{ width: "668px", height: "90px", marginTop: "24px" }}>
          {" "}
          <span
            style={{
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: "500",
              lineHeight: "25px",
              textAlign: "left",
              whiteSpace: "pre-wrap",
              marginBottom: "0px"
            }}
          >
            Hear from students who secured top seats at prestigious colleges
            through our consultation services and are now on the path to a
            brighter future.
          </span>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          gap: "85px",
          marginTop: "2rem",
          marginLeft: "82px"
        }}
      >
        {reviewsDetails.map((details, index) => (
          <div
            key={index}
            style={{
              height: "auto", // Adjust height to auto for dynamic content
              width: "388px",
              border: "0px solid black",
              position: "relative",
              boxShadow: "13px 14px 33px 0px rgba(5, 84, 242, 0.45)",
              paddingBottom: "1rem" // Add padding at the bottom for spacing
            }}
          >
            <div
              style={{
                width: "238.29px",
                height: "48.71px",
                border: "2px solid white",
                marginLeft: "10.36px",
                top: "-12.33px",
                position: "relative",
                backgroundColor: "rgba(53, 138, 192, 1)",
                zIndex: 1
              }}
            >
              <div>
                <div
                  style={{
                    marginLeft: "2.5rem",
                    fontFamily: "Manrope",
                    marginTop: "13.76px",
                    fontSize: "8.93px",
                    fontWeight: "600",
                    lineHeight: "10.84px",
                    backgroundColor: "rgba(53, 138, 192, 1)",
                    boxShadow: "15px 16px 36px 0px rgba(5, 84, 242, 0.3)"
                  }}
                >
                  {details.name}
                </div>
                <div
                  style={{
                    marginLeft: "2.5rem",
                    fontFamily: "Manrope",
                    marginBottom: "13.16px",
                    fontSize: "7.35px",
                    fontWeight: "400",
                    lineHeight: "8.67px",
                    color: "rgba(226, 226, 226, 1)"
                  }}
                >
                  {details.clgDetails}
                </div>
                <Rating
                  name={`rating-${index}`}
                  value={details.rating}
                  readOnly
                  sx={{
                    position: "absolute",
                    left: "168.78px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    "& .MuiRating-icon": {
                      fontSize: "12.12px",
                      width: "12.12px",
                      height: "12.12px",
                      color: "rgba(255, 255, 255, 1)",
                      backgroundColor: "transparent"
                    }
                  }}
                />
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                top: "-20.78px",
                left: "-20.78px",
                width: "62.01px",
                height: "66.59px",
                gap: "0px",
                boxShadow: "11.9px 14.28px 23.8px 0px rgba(57, 57, 188, 0.39)",
                zIndex: 2
              }}
            >
              <img
                src={details.image}
                alt={details.name}
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover"
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                left: "23.8px",
                top: "130px",
                transform: "translateY(-50%)", // Center vertically precisely
                width: "calc(100% - 23.8px)", // Ensure it spans the entire width of the parent
                fontFamily: "Inter",
                fontWeight: "400",
                lineHeight: "16.66px",
                fontSize: "14px", // Adjust font size as needed
                color: "rgba(0, 0, 0, 0.87)" // Adjust text color as needed
              }}
            >
              {details.review}
            </div>
            <div style={{ marginTop: "150px" }}>
              <img
                src={comma}
                alt="comma"
                style={{
                  width: "65.98px",
                  height: "45.61px",
                  top: "677px",
                  marginLeft: "18px",
                  gap: "0px",
                  opacity: 1, // Corrected to a valid value
                  objectFit: "cover"
                }}
              />
            </div>
          </div>
        ))}
        <div></div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "2rem"
        }}
      >
        <button
          style={{
            padding: "0.5rem 1rem",
            marginTop: "72px",
            borderRadius: "32px",
            marginRight: "62px",
            width: "143px",
            height: "59px",
            background: "white",
            border: "2px solid rgba(5, 84, 242, 1)",
            color: "rgba(5, 84, 242, 1)",
            alignSelf: "flex-end"
          }}
        >
          <span
            style={{
              fontFamily: "Poppins",
              fontSize: "18px",
              fontWeight: "600",
              lineHeight: "27px",
              textAlign: "left"
            }}
          >
            View More
          </span>
        </button>
      </div>
    </div>
  );
}

export default SuccessStories;
