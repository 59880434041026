import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Eapcet from "../Components/Forms/EAPCET/EapcetForm";
import Ecet from "../Components/Forms/ECET/EcetStudentForm";
import IcetStudentForm from "../Components/Forms/ICET/IcetStudentForm";
import FormPage from "../Components/Forms/FormPage";
// import Pgcet from "../Components/Form/PGCET/PgcetForm";

import Navbar from "../Components/Navbar/Navbar";
import LandingPage from "../Components/LandingPage/LandingPage";
import HeroSectionThree from "../Components/LandingPage/Navbar/HeroSectionThree";
import HeroSectionOne from "../Components/LandingPage/Navbar/HeroSectionOne";
import HeroSectionTwo from "../Components/LandingPage/Navbar/HeroSectionTwo";
import WhyChooseUs from "../Components/LandingPage/WhyChooseUsPage/WhyChooseUs";
import Footerbar from "../Components/LandingPage/Footer/Footerbar";
import SuccessStories from "../Components/LandingPage/Success/SuccessStories";
import ContactDetails from "../Components/LandingPage/Contact/ContactDetails";
import OurServices from "../Components/LandingPage/Services/OurServices";
import FormSubmissionSuccess from "../helper/Success";
//
function AppRoutes() {
  return (
    <Router>
      <Navbar />
      <Routes>
        {/* <Route path="/" element={<Navbar />} /> */}
        <Route path="/" element={<LandingPage />} />
        {/* <Route path="/one" element={<HeroSectionOne />} />
        <Route path="/two" element={<HeroSectionTwo />} />
        <Route path="/three" element={<HeroSectionThree />} /> */}

        <Route path="/student-forms" element={<FormPage />} />
        <Route path="/EAPCET/student-form" element={<Eapcet />} />
        <Route path="/ECET/student-form" element={<Ecet />} />
        <Route path="/Icet/student-form" element={<IcetStudentForm />} />
        <Route path="/success" element={<FormSubmissionSuccess />} />

        {/* <Route path="/PGCET/student-form" element={<Pgcet />} /> */}

        <Route path="/why-choose-us" element={<WhyChooseUs />} />
        <Route path="/our-success-story" element={<SuccessStories />} />
        <Route path="/contact-us" element={<ContactDetails />} />
        <Route path="/our-services" element={<OurServices />} />

        {/* <ContactDetails />e-us" element={<WhyChooseUs />} /> */}

        <Route path="/footer" element={<Footerbar />} />
        <></>
      </Routes>
    </Router>
  );
}

export default AppRoutes;
