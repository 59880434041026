import React from "react";
import styles from "./heroSectionOne.module.css";
import playButton from "./Assets/play_circle.svg";
import user_1 from "./Assets/user_1.png";
import user_2 from "./Assets/user_2.png";
import user_3 from "./Assets/user_3.png";
import user_4 from "./Assets/user_4.png";
import user_5 from "./Assets/user_5.png";
import hero_1 from "./Assets/hero_1.png";
import hero_2 from "./Assets/hero_2.jpg";
import { useNavigate } from "react-router-dom";

const HeroSectionOne = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/student-forms");
  };
  return (
    <>
      <div className={styles.mainContent}>
        <div className={styles.innerContent}>
          <span className={styles.navHeading}>
            Unlock Your Future with Top Colleges in AP & Telangana
          </span>
          <div className={styles.navDiscription}>
            Providing personalized guidance and dedicated support for students
            in Telangana and Andhra Pradesh, offering a comprehensive range of
            services to ensure successful placements and a seamless educational
            experience.
          </div>
          <div className={styles.heroButton}>
            <button onClick={handleClick}>
              <span>Get Started</span>
            </button>
            <button>
              <span>Learn More</span>
              <img src={playButton} alt="Play" />
            </button>
          </div>
          <div className={styles.users}>
            <img src={user_1} alt="User1" />
            <img src={user_2} alt="User2" />
            <img src={user_3} alt="User3" />
            <img src={user_4} alt="User4" />
            <img src={user_5} alt="User5" />
            <div className={styles.usetSum}>
              <span>10K+ Student</span>
              <span>have started their studies</span>
            </div>
          </div>
        </div>
        <div className={styles.secondContainer}>
          <img className={styles.hero1} src={hero_1} alt="Hero1" />
          <img className={styles.hero2} src={hero_2} alt="Hero2" />
        </div>
      </div>
    </>
  );
};

export default HeroSectionOne;
