import React from "react";
import HeroSectionOne from "./Navbar/HeroSectionOne";
import WhyChooseUs from "./WhyChooseUsPage/WhyChooseUs";
import OurServices from "./Services/OurServices";
import SuccessStories from "./Success/SuccessStories";
import Footerbar from "./Footer/Footerbar";
import ContactPage from "./Contact/ContactPage";
import SlidingHero from "./Navbar/SlidingHero";
import ContactDetails from "./Contact/ContactDetails";

const LandingPage = () => {
  return (
    <div>
      <SlidingHero />
      <WhyChooseUs />
      <OurServices />
      <SuccessStories />
      <ContactDetails />
      <Footerbar />
    </div>
  );
};

export default LandingPage;
