import React from "react";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import HeroSectionOne from "./HeroSectionOne";
import HeroSectionTwo from "./HeroSectionTwo";
import HeroSectionThree from "./HeroSectionThree";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./slidingHero.module.css";

const SlidingHero = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/student-forms");
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 200, // Increase the speed of slide transition
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000 // Decrease the autoplay interval
  };

  return (
    <div className={styles.sliderContainer}>
      <HeroSectionOne />
      {/* <Slider {...settings}>
        <div>
          <HeroSectionOne handleClick={handleClick} />
        </div>
        <div>
          <HeroSectionTwo handleClick={handleClick} />
        </div>
        <div>
          <HeroSectionThree handleClick={handleClick} />
        </div>
      </Slider> */}
    </div>
  );
};

export default SlidingHero;
