import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../../Api";
import CenteredCircularProgress from "../../../helper/Loading";
import ErrorDialog from "../../../helper/ErrorDialog";
import styles from "./IcetStudentForm.module.css";
import classes from "./formpage3.module.css";
import { Box, FormControl } from "@mui/material";
import SchlLogo from "../Images/YSR logo.jpeg";
// import Navbar from "../MainPage/Navbar";
import "react-datepicker/dist/react-datepicker.css";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const IcetStudentForm = () => {
  const [formData, setFormData] = useState({
    nameOfApplicant: "",
    fatherName: "",
    dateOfBirth: "",
    addressOfCommunication: "",
    phoneNumber: "",
    aadharNo: "",
    category: "",
    requestType: "ICET",
    courseLevel: "PG",
    qualifyingDetails: [
      {
        degreeClgName: "",
        university: "",
        degreePassingYear: "",
        degreeObtainedMarks: "",
        degreePercentage: "",
        degreeGrade: "",
        ICETHallTicketNo: "",
        ICETRank: ""
      }
    ],
    selectedUniversity: "",
    nameofInstution: "",
    courseName: "",
    paymentStructure: "",
    withReferenceOf: "",
    reference: [
      {
        friendName: "",
        friendPhoneNumber: ""
      }
    ]
  });

  const [page, setPage] = useState(1);
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [colleges, setColleges] = useState([]);
  const [selectedUniversity, setSelectedUniversity] = useState("");
  const [filteredColleges, setFilteredColleges] = useState([]);
  const [selectedCollge, setSelectedCollege] = useState("");

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handlePassingYear = (newValue) => {
    const passingYear = newValue.format("MM/YYYY");
    setFormData((prevFormData) => ({
      ...prevFormData,
      qualifyingDetails: [
        {
          ...prevFormData.qualifyingDetails[0],
          degreePassingYear: passingYear
        }
      ]
    }));
  };
  const handleNestedChange = (index, e) => {
    const { name, value } = e.target;
    const updatedQualifyingDetails = [...formData.qualifyingDetails];
    updatedQualifyingDetails[index][name] = value;
    setFormData({
      ...formData,
      qualifyingDetails: updatedQualifyingDetails
    });
  };

  const handleFriendChange = (index, e) => {
    const { name, value } = e.target;
    const updatedReferences = [...formData.reference];
    updatedReferences[index][name] = value;
    setFormData({
      ...formData,
      reference: updatedReferences
    });
  };

  const handleAddFriend = () => {
    setFormData({
      ...formData,
      reference: [
        ...formData.reference,
        { friendName: "", friendPhoneNumber: "" }
      ]
    });
  };

  const handleRemoveFriend = (index) => {
    if (formData.reference.length > 1) {
      const updatedReferences = formData.reference.filter(
        (reference, i) => i !== index
      );
      setFormData({
        ...formData,
        reference: updatedReferences
      });
    }
  };

  const handleGetEmployees = async () => {
    try {
      setLoading(true);
      const response = await api.getAllEmployees();
      setEmployees(response.data);
    } catch (error) {
      console.error("Error fetching employees:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetEmployees();
  }, []);

  useEffect(() => {
    fetch("http://localhost:8080/api/v1/user/admin/colleges")
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        setColleges(data.data);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedUniversity) {
      setFilteredColleges(
        colleges.filter((college) => college.university === selectedUniversity)
      );
    } else {
      setFilteredColleges([]);
    }
  }, [selectedUniversity, colleges]);

  const handlePreviewButtonClick = () => {
    // const personalDetailsFilled =
    //   formData.nameOfApplicant !== "" &&
    //   formData.fatherName !== "" &&
    //   formData.dateOfBirth !== "" &&
    //   formData.addressOfCommunication !== "" &&
    //   formData.phoneNumber !== "" &&
    //   formData.category !== "" &&
    //   formData.aadharNo!== "" &&
    //   formData.selectedUniversity !== "" &&
    //   formData.nameofInstution !== "" &&
    //   formData.courseName.length > 0 &&
    //   formData.withReferenceOf !== "";

    // if (personalDetailsFilled) {
    //   setPage(3);
    // } else {
    //   alert("Please fill out all mandatory fields in Personal details.");
    // }
    setPage(3);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/\D/g, "");
    return cleaned.length === 10;
  };
  const validateAdharNumber = (aadharNo) => {
    const cleaned = aadharNo.replace(/\D/g, "");
    return cleaned.length === 12;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!validatePhoneNumber(formData.phoneNumber)) {
      alert("Phone number must be exactly 10 digits.");
      setLoading(false);
      return;
    }
    if (!validateAdharNumber(formData.aadharNo)) {
      alert("Adhar number must be exactly 12 digits.");
      setLoading(false);
      return;
    }
    try {
      const res = await api.createIcetStudent(formData);
      if (res.success) {
        navigate("/success");
      } else {
        setMessage(
          "Error Submitting your Form: " + (res.message || res.statusText)
        );
        setOpen(true);
      }
    } catch (error) {
      setLoading(false);
      if (error.response) {
        setMessage(
          "Error Submitting your Form: " +
            (error.response.data.message || error.response.status)
        );
      } else if (error.request) {
        setMessage("Error Submitting your Form: Network error");
      } else {
        setMessage(error.message);
      }
      setOpen(true);
    }
    setLoading(false);
  };

  const handleUniversityChange = (event) => {
    const value = event.target.value;
    setSelectedUniversity(value);
    setFormData({
      ...formData,
      selectedUniversity: value
    });
  };
  const handleCollegeChange = (event) => {
    const value = event.target.value;
    setSelectedCollege(value);
    setFormData({
      ...formData,
      nameofInstution: value
    });
  };
  if (loading) {
    return <CenteredCircularProgress />;
  }

  return (
    <>
      <div className={styles.container}>
        <form className={styles.formWrapper} onSubmit={handleSubmit}>
          {page === 1 && (
            <div>
              <h3 className={styles.responsive_heading}>
                Qualifying Details for MBA/MCA
              </h3>
              {formData.qualifyingDetails.map((detail, index) => (
                <React.Fragment key={index}>
                  <label className={styles.label}>Enter University:</label>
                  <select
                    type="text"
                    name="university"
                    className={styles.input}
                    value={detail.university}
                    onChange={(e) => handleNestedChange(index, e)}
                  >
                    <option value="" disabled>
                      Select University
                    </option>
                    <optgroup label="Andhra Pradesh">
                      <option value="Acharya Nagarjuna University (ANU)">
                        Acharya Nagarjuna University (ANU)
                      </option>
                      <option value="Andhra University (AU)">
                        Andhra University (AU)
                      </option>
                      <option value="Dr. NTR University of Health Sciences (NTRUHS)">
                        Dr. NTR University of Health Sciences (NTRUHS)
                      </option>
                      <option value="Dravidian University (DU)">
                        Dravidian University (DU)
                      </option>
                      <option value="GITAM University (GITAM)">
                        GITAM University (GITAM)
                      </option>
                      <option value="Jawaharlal Nehru Technological University, Anantapur (JNTU-A)">
                        Jawaharlal Nehru Technological University, Anantapur
                        (JNTU-A)
                      </option>
                      <option value="Jawaharlal Nehru Technological University, Kakinada (JNTU-K)">
                        Jawaharlal Nehru Technological University, Kakinada
                        (JNTU-K)
                      </option>
                      <option value="Sri Krishnadevaraya University (SKU)">
                        Sri Krishnadevaraya University (SKU)
                      </option>
                      <option value="Sri Venkateswara University (SVU)">
                        Sri Venkateswara University (SVU)
                      </option>
                      <option value="Vikrama Simhapuri University (VSU)">
                        Vikrama Simhapuri University (VSU)
                      </option>
                      <option value="Yogi Vemana University (YVU)">
                        Yogi Vemana University (YVU)
                      </option>
                    </optgroup>

                    <optgroup label="Arunachal Pradesh">
                      <option value="Arunachal University of Studies (AUS)">
                        Arunachal University of Studies (AUS)
                      </option>
                      <option value="North East Regional Institute of Science and Technology (NERIST)">
                        North East Regional Institute of Science and Technology
                        (NERIST)
                      </option>
                    </optgroup>

                    <optgroup label="Assam">
                      <option value="Assam University (AU)">
                        Assam University (AU)
                      </option>
                      <option value="Dibrugarh University (DU)">
                        Dibrugarh University (DU)
                      </option>
                      <option value="Gauhati University (GU)">
                        Gauhati University (GU)
                      </option>
                      <option value="Tezpur University (TU)">
                        Tezpur University (TU)
                      </option>
                      <option value="Bodoland University">
                        Bodoland University
                      </option>
                    </optgroup>

                    <optgroup label="Bihar">
                      <option value="Bihar Agricultural University (BAU)">
                        Bihar Agricultural University (BAU)
                      </option>
                      <option value="Babasaheb Bhimrao Ambedkar Bihar University (BRABU)">
                        Babasaheb Bhimrao Ambedkar Bihar University (BRABU)
                      </option>
                      <option value="Magadh University">
                        Magadh University
                      </option>
                      <option value="Nalanda University">
                        Nalanda University
                      </option>
                      <option value="Patna University">Patna University</option>
                    </optgroup>

                    <optgroup label="Chhattisgarh">
                      <option value="All India Institute of Medical Sciences, Raipur (AIIMS Raipur)">
                        All India Institute of Medical Sciences, Raipur (AIIMS
                        Raipur)
                      </option>
                      <option value="Bhilai Institute of Technology (BIT)">
                        Bhilai Institute of Technology (BIT)
                      </option>
                      <option value="Chhattisgarh Swami Vivekanand Technical University (CSVTU)">
                        Chhattisgarh Swami Vivekanand Technical University
                        (CSVTU)
                      </option>
                      <option value="Guru Ghasidas Vishwavidyalaya (GGU)">
                        Guru Ghasidas Vishwavidyalaya (GGU)
                      </option>
                    </optgroup>

                    <optgroup label="Goa">
                      <option value="Goa University (GU)">
                        Goa University (GU)
                      </option>
                    </optgroup>

                    <optgroup label="Gujarat">
                      <option value="Gujarat University (GU)">
                        Gujarat University (GU)
                      </option>
                      <option value="Sardar Patel University (SPU)">
                        Sardar Patel University (SPU)
                      </option>
                      <option value="Maharaja Sayajirao University of Baroda (MSU)">
                        Maharaja Sayajirao University of Baroda (MSU)
                      </option>
                      <option value="Nirma University">Nirma University</option>
                      <option value="Dhirubhai Ambani Institute of Information and Communication Technology (DA-IICT)">
                        Dhirubhai Ambani Institute of Information and
                        Communication Technology (DA-IICT)
                      </option>
                    </optgroup>

                    <optgroup label="Haryana">
                      <option value="Kurukshetra University (KU)">
                        Kurukshetra University (KU)
                      </option>
                      <option value="Panjab University (PU)">
                        Panjab University (PU)
                      </option>
                      <option value="Maharshi Dayanand University (MDU)">
                        Maharshi Dayanand University (MDU)
                      </option>
                      <option value="Haryana Agricultural University">
                        Haryana Agricultural University
                      </option>
                    </optgroup>

                    <optgroup label="Himachal Pradesh">
                      <option value="Himachal Pradesh University (HPU)">
                        Himachal Pradesh University (HPU)
                      </option>
                      <option value="National Institute of Technology, Hamirpur (NIT-Hamirpur)">
                        National Institute of Technology, Hamirpur
                        (NIT-Hamirpur)
                      </option>
                    </optgroup>

                    <optgroup label="Jharkhand">
                      <option value="Jharkhand University of Technology (JUT)">
                        Jharkhand University of Technology (JUT)
                      </option>
                      <option value="Ranchi University">
                        Ranchi University
                      </option>
                      <option value="Vinoba Bhave University (VBU)">
                        Vinoba Bhave University (VBU)
                      </option>
                      <option value="Nilamber Pitamber University">
                        Nilamber Pitamber University
                      </option>
                    </optgroup>

                    <optgroup label="Karnataka">
                      <option value="Bangalore University (BU)">
                        Bangalore University (BU)
                      </option>
                      <option value="Indian Institute of Science (IISc)">
                        Indian Institute of Science (IISc)
                      </option>
                      <option value="National Institute of Technology Karnataka (NIT-K)">
                        National Institute of Technology Karnataka (NIT-K)
                      </option>
                      <option value="Visvesvaraya Technological University (VTU)">
                        Visvesvaraya Technological University (VTU)
                      </option>
                      <option value="Jawaharlal Nehru Centre for Advanced Scientific Research (JNCASR)">
                        Jawaharlal Nehru Centre for Advanced Scientific Research
                        (JNCASR)
                      </option>
                    </optgroup>

                    <optgroup label="Kerala">
                      <option value="University of Kerala">
                        University of Kerala
                      </option>
                      <option value="Cochin University of Science and Technology (CUSAT)">
                        Cochin University of Science and Technology (CUSAT)
                      </option>
                      <option value="National Institute of Technology Calicut (NITC)">
                        National Institute of Technology Calicut (NITC)
                      </option>
                      <option value="Kerala Agricultural University (KAU)">
                        Kerala Agricultural University (KAU)
                      </option>
                    </optgroup>

                    <optgroup label="Madhya Pradesh">
                      <option value="Jawaharlal Nehru University (JNU)">
                        Jawaharlal Nehru University (JNU)
                      </option>
                      <option value="Madhya Pradesh Bhoj (Open) University">
                        Madhya Pradesh Bhoj (Open) University
                      </option>
                      <option value="Rani Durgavati Vishwavidyalaya (RDVV)">
                        Rani Durgavati Vishwavidyalaya (RDVV)
                      </option>
                      <option value="Dr. Harisingh Gour Vishwavidyalaya (DHSGSU)">
                        Dr. Harisingh Gour Vishwavidyalaya (DHSGSU)
                      </option>
                      <option value="National Institute of Technology, Raipur (NIT-Raipur)">
                        National Institute of Technology, Raipur (NIT-Raipur)
                      </option>
                    </optgroup>

                    <optgroup label="Maharashtra">
                      <option value="University of Mumbai (MU)">
                        University of Mumbai (MU)
                      </option>
                      <option value="Savitribai Phule Pune University (SPPU)">
                        Savitribai Phule Pune University (SPPU)
                      </option>
                      <option value="Jawaharlal Nehru University (JNU)">
                        Jawaharlal Nehru University (JNU)
                      </option>
                      <option value="Indian Institute of Technology Bombay (IITB)">
                        Indian Institute of Technology Bombay (IITB)
                      </option>
                      <option value="Institute of Chemical Technology (ICT)">
                        Institute of Chemical Technology (ICT)
                      </option>
                      <option value="Shivaji University">
                        Shivaji University
                      </option>
                      <option value="Bharati Vidyapeeth">
                        Bharati Vidyapeeth
                      </option>
                    </optgroup>

                    <optgroup label="Manipur">
                      <option value="Manipur University">
                        Manipur University
                      </option>
                      <option value="Central Agricultural University">
                        Central Agricultural University
                      </option>
                    </optgroup>

                    <optgroup label="Meghalaya">
                      <option value="North Eastern Hill University (NEHU)">
                        North Eastern Hill University (NEHU)
                      </option>
                    </optgroup>

                    <optgroup label="Mizoram">
                      <option value="Mizoram University (MU)">
                        Mizoram University (MU)
                      </option>
                    </optgroup>

                    <optgroup label="Nagaland">
                      <option value="Nagaland University (NU)">
                        Nagaland University (NU)
                      </option>
                    </optgroup>

                    <optgroup label="Odisha">
                      <option value="Utkal University">Utkal University</option>
                      <option value="Sambalpur University">
                        Sambalpur University
                      </option>
                      <option value="Berhampur University">
                        Berhampur University
                      </option>
                      <option value="National Institute of Technology Rourkela (NIT-Rourkela)">
                        National Institute of Technology Rourkela (NIT-Rourkela)
                      </option>
                    </optgroup>

                    <optgroup label="Punjab">
                      <option value="Panjab University (PU)">
                        Panjab University (PU)
                      </option>
                      <option value="Punjab Agricultural University (PAU)">
                        Punjab Agricultural University (PAU)
                      </option>
                      <option value="Guru Nanak Dev University (GNDU)">
                        Guru Nanak Dev University (GNDU)
                      </option>
                    </optgroup>

                    <optgroup label="Rajasthan">
                      <option value="University of Rajasthan (UOR)">
                        University of Rajasthan (UOR)
                      </option>
                      <option value="Maharshi Dayanand Saraswati University (MDSU)">
                        Maharshi Dayanand Saraswati University (MDSU)
                      </option>
                      <option value="Rajasthan Technical University (RTU)">
                        Rajasthan Technical University (RTU)
                      </option>
                      <option value="Banasthali Vidyapith">
                        Banasthali Vidyapith
                      </option>
                    </optgroup>

                    <optgroup label="Sikkim">
                      <option value="Sikkim University">
                        Sikkim University
                      </option>
                    </optgroup>

                    <optgroup label="Tamil Nadu">
                      <option value="University of Madras">
                        University of Madras
                      </option>
                      <option value="Anna University">Anna University</option>
                      <option value="Indian Institute of Technology Madras (IITM)">
                        Indian Institute of Technology Madras (IITM)
                      </option>
                      <option value="Bharathiar University">
                        Bharathiar University
                      </option>
                      <option value="Vellore Institute of Technology (VIT)">
                        Vellore Institute of Technology (VIT)
                      </option>
                      <option value="Tamil Nadu Agricultural University (TNAU)">
                        Tamil Nadu Agricultural University (TNAU)
                      </option>
                    </optgroup>

                    <optgroup label="Telangana">
                      <option value="Jawaharlal Nehru Technological University, Hyderabad (JNTU-H)">
                        Jawaharlal Nehru Technological University, Hyderabad
                        (JNTU-H)
                      </option>
                      <option value="Kakatiya University (KU)">
                        Kakatiya University (KU)
                      </option>
                      <option value="Osmania University (OU)">
                        Osmania University (OU)
                      </option>
                      <option value="University of Hyderabad (UoH)">
                        University of Hyderabad (UoH)
                      </option>
                      <option value="NALSAR University of Law (NALSAR)">
                        NALSAR University of Law (NALSAR)
                      </option>
                      <option value="International Institute of Information Technology, Hyderabad (IIIT-H)">
                        International Institute of Information Technology,
                        Hyderabad (IIIT-H)
                      </option>
                      <option value="Potti Sreeramulu Telugu University (PSTU)">
                        Potti Sreeramulu Telugu University (PSTU)
                      </option>
                      <option value="Telangana University (TU)">
                        Telangana University (TU)
                      </option>
                      <option value="Palamuru University (PU)">
                        Palamuru University (PU)
                      </option>
                      <option value="Satavahana University (SU)">
                        Satavahana University (SU)
                      </option>
                      <option value="Mahatma Gandhi University (MGU)">
                        Mahatma Gandhi University (MGU)
                      </option>
                      <option value="Konda Laxman Telangana State Horticultural University (KLTSHU)">
                        Konda Laxman Telangana State Horticultural University
                        (KLTSHU)
                      </option>
                      <option value="Maulana Azad National Urdu University (MANUU)">
                        Maulana Azad National Urdu University (MANUU)
                      </option>
                    </optgroup>

                    <optgroup label="Tripura">
                      <option value="Tripura University">
                        Tripura University
                      </option>
                    </optgroup>

                    <optgroup label="Uttar Pradesh">
                      <option value="University of Lucknow">
                        University of Lucknow
                      </option>
                      <option value="Dr. Ram Manohar Lohia Avadh University (RMLAU)">
                        Dr. Ram Manohar Lohia Avadh University (RMLAU)
                      </option>
                      <option value="Indian Institute of Technology Kanpur (IITK)">
                        Indian Institute of Technology Kanpur (IITK)
                      </option>
                      <option value="Banaras Hindu University (BHU)">
                        Banaras Hindu University (BHU)
                      </option>
                      <option value="Aligarh Muslim University (AMU)">
                        Aligarh Muslim University (AMU)
                      </option>
                      <option value="Chaudhary Charan Singh University (CCSU)">
                        Chaudhary Charan Singh University (CCSU)
                      </option>
                      <option value="Jawaharlal Nehru University (JNU)">
                        Jawaharlal Nehru University (JNU)
                      </option>
                    </optgroup>

                    <optgroup label="Uttarakhand">
                      <option value="Uttarakhand Technical University (UTU)">
                        Uttarakhand Technical University (UTU)
                      </option>
                      <option value="Hemwati Nandan Bahuguna Garhwal University (HNB Garhwal)">
                        Hemwati Nandan Bahuguna Garhwal University (HNB Garhwal)
                      </option>
                      <option value="Dehradun Institute of Technology (DIT)">
                        Dehradun Institute of Technology (DIT)
                      </option>
                    </optgroup>

                    <optgroup label="West Bengal">
                      <option value="University of Calcutta">
                        University of Calcutta
                      </option>
                      <option value="Jadavpur University">
                        Jadavpur University
                      </option>
                      <option value="Calcutta University">
                        Calcutta University
                      </option>
                      <option value="Indian Statistical Institute (ISI)">
                        Indian Statistical Institute (ISI)
                      </option>
                      <option value="West Bengal University of Animal and Fishery Sciences (WBUAFS)">
                        West Bengal University of Animal and Fishery Sciences
                        (WBUAFS)
                      </option>
                      <option value="Bidhan Chandra Krishi Vishwavidyalaya">
                        Bidhan Chandra Krishi Vishwavidyalaya
                      </option>
                    </optgroup>
                    <option value="Others">Others</option>
                  </select>
                  <label className={styles.label}>Degree College Name:</label>
                  <input
                    type="text"
                    name="degreeClgName"
                    className={styles.input}
                    value={detail.degreeClgName}
                    onChange={(e) => handleNestedChange(index, e)}
                  />
                  <label className={styles.label}>
                    Degree Passing Month and Year:
                  </label>{" "}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["DatePicker", "DatePicker", "DatePicker"]}
                    >
                      <Box sx={{ display: "flex", gap: 2 }}>
                        <DatePicker
                          views={["month", "year"]}
                          className={styles.input}
                          onChange={handlePassingYear}
                          sx={{
                            width: "100%", // Set the desired width
                            "& .MuiInputBase-root": {
                              border: "none",
                              "& fieldset": {
                                border: "none",
                                padding: "1px"
                              }
                            }
                          }}
                        />
                      </Box>
                    </DemoContainer>
                  </LocalizationProvider>
                  {/* <input
                    type="number"
                    name="degreePassingYear"
                    className={styles.input}
                    value={detail.degreePassingYear}
                    onChange={(e) => handleNestedChange(index, e)}
                    min="1900"
                    max="2100"
                    step="1"
                  /> */}
                  <label className={styles.label}>Degree Mark Obtained:</label>
                  <input
                    type="number"
                    name="degreeObtainedMarks"
                    className={styles.input}
                    value={detail.degreeObtainedMarks}
                    onChange={(e) => handleNestedChange(index, e)}
                  />
                  <label className={styles.label}>Degree Percentage:</label>
                  <input
                    type="number"
                    name="degreePercentage"
                    className={styles.input}
                    value={detail.degreePercentage}
                    onChange={(e) => handleNestedChange(index, e)}
                    min="33.33"
                    max="100"
                    step="0.1"
                  />
                  <label className={styles.label}>Degree Grade:</label>
                  <select
                    name="degreeGrade"
                    className={styles.input}
                    value={detail.degreeGrade}
                    onChange={(e) => handleNestedChange(index, e)}
                  >
                    <option value="">Select Grade</option>
                    <option value="S">S</option>
                    <option value="A+">A+</option>
                    <option value="A">A</option>
                    <option value="A-">A-</option>
                    <option value="B+">B+</option>
                    <option value="B">B</option>
                    <option value="B-">B-</option>
                    <option value="C+">C+</option>
                    <option value="C">C</option>
                    <option value="C-">C-</option>
                    <option value="D+">D+</option>
                    <option value="D">D</option>
                    <option value="D-">D-</option>
                    <option value="F">F</option>
                  </select>
                  <label className={styles.label}>ICET Hall Ticket No:</label>
                  <input
                    type="text"
                    name="ICETHallTicketNo"
                    className={styles.input}
                    value={detail.ICETHallTicketNo}
                    onChange={(e) => handleNestedChange(index, e)}
                  />
                  <label className={styles.label}>ICET Rank:</label>
                  <input
                    type="number"
                    name="ICETRank"
                    className={styles.input}
                    value={detail.ICETRank}
                    onChange={(e) => handleNestedChange(index, e)}
                  />
                </React.Fragment>
              ))}
              <button
                type="button"
                className={styles.nextButton}
                onClick={() => setPage(2)}
              >
                Next
              </button>
            </div>
          )}

          {page === 2 && (
            <div>
              <h3 className={styles.responsive_heading}>Personals Details</h3>
              <label className={styles.label}>Select Date:</label>
              <input
                type="date"
                name="date"
                className={styles.input}
                value={formData.date}
                onChange={handleChange}
              />
              <label className={styles.label}>Name of Applicant:</label>
              <input
                type="text"
                name="nameOfApplicant"
                className={styles.input}
                value={formData.nameOfApplicant}
                onChange={handleChange}
              />
              <label className={styles.label}>Father's Name:</label>
              <input
                type="text"
                name="fatherName"
                className={styles.input}
                value={formData.fatherName}
                onChange={handleChange}
              />
              <label className={styles.label}>Date of Birth:</label>
              <input
                type="date"
                name="dateOfBirth"
                className={styles.input}
                value={formData.dateOfBirth}
                onChange={handleChange}
              />
              <label className={styles.label}>Address of Communication:</label>
              <input
                type="text"
                name="addressOfCommunication"
                className={styles.input}
                value={formData.addressOfCommunication}
                onChange={handleChange}
              />
              <label className={styles.label}>Phone Number:</label>
              <input
                type="text"
                name="phoneNumber"
                className={styles.input}
                value={formData.phoneNumber}
                onChange={handleChange}
                maxLength="10"
              />
              <label className={styles.label}>Aadhar Number:</label>
              <input
                type="text"
                name="aadharNo"
                className={styles.input}
                value={formData.aadharNo}
                onChange={handleChange}
                maxLength="12"
              />
              <label className={styles.label}>Category:</label>
              <select
                name="category"
                className={styles.input}
                value={formData.category}
                onChange={handleChange}
              >
                <option value="" disabled>
                  Select Category
                </option>
                <option value="OC">OC</option>
                <option value="OC/EWS">OC/EWS</option>
                <option value="BCA">BCA</option>
                <option value="BCB">BCB</option>
                <option value="BCC">BCC</option>
                <option value="BCD">BCD</option>
                <option value="SC">SC</option>
                <option value="ST">ST</option>
              </select>
              {/* <label className={styles.label}>Name of Institution:</label>
              <input
                type="text"
                name="nameofInstution"
                className={styles.input}
                value={formData.nameofInstution}
                onChange={handleChange}
              /> */}
              <label htmlFor="university">Select University:</label>
              <select
                id="university"
                className={styles.input}
                value={selectedUniversity}
                onChange={handleUniversityChange}
              >
                <option value="">Select University</option>
                {Array.from(
                  new Set(colleges.map((college) => college.university))
                ).map((university) => (
                  <option key={university} value={university}>
                    {university}
                  </option>
                ))}
                <option value="Others">Others</option>
              </select>
              {selectedUniversity === "Others" ? (
                <>
                  <div>
                    <label htmlFor="selectedUniversity">
                      Enter University Name:
                    </label>
                    <input
                      type="text"
                      id="selectedUniversity"
                      className={styles.input}
                      value={formData.selectedUniversity}
                      onChange={handleChange}
                      placeholder="Enter University Name"
                      name="selectedUniversity"
                    />
                  </div>
                  <div>
                    <label htmlFor="nameofInstution">Enter College Name:</label>
                    <input
                      type="text"
                      id="nameofInstution"
                      className={styles.input}
                      value={formData.nameofInstution}
                      onChange={handleChange}
                      placeholder="Enter College Name"
                      name="nameofInstution"
                    />
                  </div>
                </>
              ) : (
                selectedUniversity && (
                  <div>
                    <label htmlFor="college">Select College:</label>
                    <select
                      id="college"
                      className={styles.input}
                      value={formData.nameofInstution}
                      onChange={handleCollegeChange}
                    >
                      <option value="">Select College</option>
                      {filteredColleges.map((college) => (
                        <option key={college.clgName} value={college.clgName}>
                          {college.clgName}
                        </option>
                      ))}
                      <option value="Others">Others</option>
                    </select>
                  </div>
                )
              )}
              {formData.nameofInstution === "Others" && (
                <div>
                  <label htmlFor="nameofInstution">Enter College Name:</label>
                  <input
                    type="text"
                    id="nameofInstution"
                    className={styles.input}
                    value={formData.nameofInstution}
                    onChange={handleChange}
                    placeholder="Enter College Name"
                  />
                </div>
              )}

              <label className={styles.label}>Course Name:</label>
              <select
                name="courseName"
                className={styles.input}
                value={formData.courseName}
                onChange={handleChange}
              >
                <option value="" disabled>
                  Select Course
                </option>
                <option value="MCA">MCA</option>
                <option value="MBA">MBA</option>
              </select>
              <label className={styles.label}>Payment Structure:</label>
              <select
                name="paymentStructure"
                value={formData.paymentStructure}
                onChange={handleChange}
                className={styles.input}
              >
                <option value="">Select Payment Structure</option>
                <option value="One Time Payment">One Time Payment</option>
                <option value="Annual Payment">Annual Payment</option>
              </select>
              <label className={styles.label}>With Reference Of:</label>
              <select
                name="withReferenceOf"
                className={styles.select}
                value={formData.withReferenceOf}
                onChange={handleChange}
              >
                <option value="">Select an Employee</option>
                {employees.map((employee) => (
                  <option
                    key={`${employee.name} (${employee.phoneNumber})`}
                    value={`${employee.name} (${employee.phoneNumber})`}
                  >
                    {`${employee.name} (${employee.phoneNumber})`}
                  </option>
                ))}
              </select>
              <label className={styles.label}>Friend Details:</label>
              {formData.reference.map((reference, index) => (
                <div key={index} className={styles.friendContainer}>
                  <input
                    type="text"
                    className={styles.input}
                    name="friendName"
                    value={reference.friendName}
                    onChange={(e) => handleFriendChange(index, e)}
                    placeholder="Enter Friend Name"
                  />
                  <input
                    type="text"
                    className={styles.input}
                    name="friendPhoneNumber"
                    value={reference.friendPhoneNumber}
                    onChange={(e) => handleFriendChange(index, e)}
                    placeholder="Enter Friend Number"
                  />
                  {formData.reference.length > 1 && (
                    <button
                      type="button"
                      className={styles.removeButton}
                      onClick={() => handleRemoveFriend(index)}
                    >
                      Remove
                    </button>
                  )}
                </div>
              ))}
              <button
                type="button"
                className={styles.addButton}
                onClick={handleAddFriend}
              >
                + Add
              </button>
              <div className={styles.buttonContainer}>
                <button
                  type="button"
                  className={styles.styledButton}
                  onClick={() => setPage(1)}
                >
                  Previous
                </button>
                <button
                  type="button"
                  className={styles.styledButton}
                  onClick={handlePreviewButtonClick}
                >
                  Preview
                </button>
                {/* <button type="submit" className={styles.styledButton}>
                Submit
              </button> */}
              </div>
            </div>
          )}
          {page === 3 && (
            <FormControl style={{ paddingBottom: "0px", paddingTop: "70px" }}>
              <div className={classes.formParentContainer}>
                <div style={{ borderBottom: "3px solid black" }}>
                  <div className={classes.headerContainer}>
                    <div></div>
                    <div className={classes.imageContainer}>
                      <img src={SchlLogo} className={classes.responsiveImage} />
                      <div>
                        <h1 className={classes.headerText}>
                          YSR EDUCATIONAL SERVICES
                        </h1>
                        <h6 className={classes.subMain}>
                          "Enlightening the values of education"
                        </h6>
                        <p className={classes.address}>
                          Flat No 6-36/4,Room No 4, kukatpally Bus stop,Moosapet
                        </p>
                        <p className={classes.address1}>
                          Hyderabad-500-018,.T.S., India Cell:9959693939,
                          9133423939
                        </p>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
                <div className={classes.formContainer}>
                  <p className={classes.preliminary}>
                    <u style={{ fontSize: "bold" }}>
                      PRELIMINARY APPLICATION FORM
                    </u>
                  </p>
                  <div className={classes.dateContainer}>
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        marginTop: "8px"
                      }}
                    >
                      Date:-
                    </p>
                    <div className={classes.dateText}>
                      <div onChange={(date) => setSelectedDate(date)}>
                        {formData.date}
                      </div>
                    </div>
                  </div>
                  <div className={classes.formContents}>
                    <div className={classes.fieldContainer}>
                      <div style={{ width: "100%" }}>
                        <div className={classes.forMobileView}>
                          <p className={classes.formFieldText}>
                            {" "}
                            1.Name Of The Applicant
                          </p>{" "}
                          <span className={classes.colanMobile}>:</span>
                        </div>
                        <p className={classes.formFieldText}>
                          (IN Block Letters As per SSC)
                        </p>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div className={classes.colan}>:</div>
                        <div
                          style={{ width: "100%" }}
                          className={classes.inputField}
                          type="text"
                        >
                          {formData.nameOfApplicant}
                        </div>
                      </div>
                    </div>
                    <div className={classes.fieldContainer}>
                      <div className={classes.forMobileView}>
                        <p className={classes.formFieldText}>
                          {" "}
                          2.Father's Name
                        </p>{" "}
                        <span className={classes.colanMobile}>:</span>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div className={classes.colan}>:</div>
                        <div
                          style={{ width: "100%" }}
                          className={classes.inputField}
                          type="text"
                        >
                          {formData.fatherName}
                        </div>
                      </div>
                    </div>
                    <div className={classes.fieldContainer}>
                      <div className={classes.forMobileView}>
                        <p className={classes.formFieldText}>
                          {" "}
                          3.Date Of Birth
                        </p>{" "}
                        <span className={classes.colanMobile}>:</span>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div className={classes.colan}>:</div>
                        <div
                          style={{ width: "100%" }}
                          className={classes.inputField}
                          type="text"
                        >
                          {formData.dateOfBirth}
                        </div>
                      </div>
                    </div>
                    <div className={classes.fieldContainer}>
                      <div className={classes.forMobileView}>
                        <p className={classes.formFieldText}>
                          {" "}
                          4.Address For Communication
                        </p>{" "}
                        <span className={classes.colanMobile}>:</span>
                      </div>
                      <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div className={classes.colan}>:</div>
                          <div
                            style={{ width: "100%" }}
                            className={classes.inputField}
                          >
                            {formData.addressOfCommunication}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={classes.fieldContainer}>
                      <div className={classes.forMobileView}>
                        <p className={classes.formFieldText}>
                          5.Tel No/Mobile No
                        </p>{" "}
                        <span className={classes.colanMobile}>:</span>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div className={classes.colan}>:</div>
                        <div
                          style={{ width: "100%" }}
                          className={classes.inputField}
                          type="text"
                        >
                          {formData.phoneNumber}
                        </div>
                      </div>
                    </div>
                    <div className={classes.fieldContainer}>
                      <div className={classes.forMobileView}>
                        <p className={classes.formFieldText}>6.Aadhar No</p>{" "}
                        <span className={classes.colanMobile}>:</span>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div className={classes.colan}>:</div>
                        <div
                          style={{ width: "100%" }}
                          className={classes.inputField}
                          type="text"
                        >
                          {formData.aadharNo}
                        </div>
                      </div>
                    </div>
                    <div className={classes.fieldContainer}>
                      <div style={{ width: "100%" }}>
                        <div className={classes.forMobileView}>
                          <p className={classes.formFieldText}>
                            7.Categorey Mangagement Quota/NRI
                          </p>{" "}
                          <span className={classes.colanMobile}>:</span>
                        </div>
                        <p className={classes.formFieldText}>
                          Foreign Students (If Applicable)
                        </p>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div className={classes.colan}>:</div>
                        <input
                          style={{ width: "100%" }}
                          className={classes.inputField}
                          type="text"
                        />
                      </div>
                    </div>
                    <div className={classes.fieldContainer}>
                      <div className={classes.forMobileView}>
                        <p className={classes.formFieldText}>
                          8.OC/BC (A,B,C,D) SC/ST
                        </p>{" "}
                        <span className={classes.colanMobile}>:</span>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div className={classes.colan}>:</div>
                        <div
                          style={{ width: "100%" }}
                          className={classes.inputField}
                          type="text"
                        >
                          {formData.category}
                        </div>
                      </div>
                    </div>
                    <div style={{ width: "100%", marginTop: "8px" }}>
                      <p className={classes.formFieldText}>
                        9.Details of Previous Qualifying Degree
                      </p>
                      <div style={{ overflowX: "auto" }}>
                        <table className={classes.tableContainer}>
                          <tr className={classes.tableRow}>
                            <th className={classes.tableColumn}>Course</th>
                            <th className={classes.tableColumn}>University</th>
                            <th className={classes.tableColumn}>College</th>
                            <th className={classes.tableColumn}>
                              Month & year of passing
                            </th>
                            <th className={classes.tableColumn}>
                              Percentage Of Marks
                            </th>
                            <th className={classes.tableColumn}>ICET H.NO</th>
                            <th className={classes.tableColumn}>ICET Rank</th>
                          </tr>
                          <tr className={classes.tableRow}>
                            <th className={classes.tableColumn}>Degree</th>
                            <td className={classes.tableColumn}>
                              <div className={classes.tableInput} type="text">
                                {formData.qualifyingDetails[0].university}
                              </div>
                            </td>
                            <td className={classes.tableColumn}>
                              <div className={classes.tableInput} type="text">
                                {formData.qualifyingDetails[0].degreeClgName}
                              </div>
                            </td>
                            <td className={classes.tableColumn}>
                              <div className={classes.tableInput} type="text">
                                {" "}
                                {
                                  formData.qualifyingDetails[0]
                                    .degreePassingYear
                                }
                              </div>
                            </td>

                            <td className={classes.tableColumn}>
                              <div className={classes.tableInput} type="text">
                                {" "}
                                {formData.qualifyingDetails[0].degreePercentage}
                              </div>
                            </td>
                            <td className={classes.tableColumn}>
                              <div className={classes.tableInput} type="text">
                                {" "}
                                {formData.qualifyingDetails[0].ICETHallTicketNo}
                              </div>
                            </td>
                            <td className={classes.tableColumn}>
                              <div className={classes.tableInput} type="text">
                                {formData.qualifyingDetails[0].ICETRank}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div style={{ width: "100%", marginTop: "8px" }}>
                        <p className={classes.formFieldText}>
                          10.Name Of the Instution in which Admission is
                          Required:
                        </p>
                        <div style={{ width: "100%" }}>
                          <div
                            style={{ width: "100%" }}
                            className={classes.inputField}
                            type="text"
                          >
                            {formData.nameofInstution}
                          </div>
                        </div>
                      </div>
                      <div style={{ width: "100%", marginTop: "8px" }}>
                        <p className={classes.formFieldText}>
                          11.Name Of the Course in which Admission is Required:
                        </p>
                        <div className={classes.formFields}>
                          <p
                            className={classes.formFieldText}
                          >{`${formData.courseName}`}</p>
                        </div>
                      </div>
                    </div>

                    <div style={{ width: "100%", marginTop: "8px" }}>
                      <p className={classes.formFieldText}>
                        {`12.with Referance of:- `}
                        {formData.withReferenceOf}
                      </p>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        justifyContent: "space-between",
                        marginTop: "8px"
                      }}
                    >
                      <p
                        className={classes.formFieldText}
                      >{`13.Referance:Mention the name of two persons known you with phone numbers of:`}</p>
                      <div>
                        {formData.reference.map((friend, index) => (
                          <div>
                            <p>{index + 1}. </p>
                            <div>
                              {friend.friendName} <br />{" "}
                              {friend.friendPhoneNumber}
                            </div>
                          </div>
                        ))}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexWrap: "wrap",
                          width: "100%",
                          marginTop: "8px"
                        }}
                      >
                        <div>
                          <input
                            style={{ width: "100%" }}
                            className={classes.inputField}
                            type="text"
                          />
                          <p
                            className={classes.formFieldText}
                          >{`SIGNATURE OF THE PARENT`}</p>
                        </div>
                        <div>
                          <input
                            style={{ width: "100%" }}
                            className={classes.inputField}
                            type="text"
                          />
                          <p
                            className={classes.formFieldText}
                          >{`SIGNATURE OF THE STUDENT`}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.buttonContainer}>
                <button
                  type="button"
                  className={styles.styledButton}
                  onClick={() => setPage(2)}
                >
                  Previous
                </button>
                <button type="submit" className={styles.styledButton}>
                  Submit
                </button>
              </div>
            </FormControl>
          )}
        </form>
        {/* <ErrorDialog open={open} setOpen={setOpen} message={message} /> */}
        <ErrorDialog
          open={open}
          message={message}
          handleClose={() => setOpen(false)}
          retry={handleSubmit} // Retry submission
        />
      </div>
    </>
  );
};

export default IcetStudentForm;
