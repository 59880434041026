import React from "react";
import { useNavigate } from "react-router-dom";
import bgImage from "./Images/4.jpg";
import styled from "styled-components";
// import Navbar from "../MainPage/Navbar";

const Container = styled.div`
  max-margin: auto;
  padding: 15px;
  text-align: center;
  margin-bottom: 5px;
  @media (max-width: 600px) {
    padding: 20px;
  }
  background-image: url(${bgImage});
  background-size: cover;
  background-position: auto;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled.button`
  margin: auto;
  width: 100%;
  height: 40px;
  margin-bottom: 5px;
  background-color: #61dafb;
  border: none;
  cursor: pointer;

  color: black;
  font-weight: bold;
  border-radius: 100px;

  &:hover {
    background: #21a1f1;
    font-weight: bold;
  }
`;

const Div = styled.div`
  width: 150px;
`;

const FormPage = () => {
  const navigate = useNavigate();

  const handleButtonClick = (examType) => () => {
    if (examType === "EAPCET") {
      navigate("/EAPCET/student-form");
    } else if (examType === "ECET") {
      navigate("/ECET/student-form");
    } else if (examType === "ICET") {
      navigate("/ICET/student-form");
    } else if (examType === "PGCET") {
      navigate("/PGCET/student-form");
    } else if (examType === "MANAGEMENT") {
      navigate("/management/student-form");
    }
  };

  return (
    <>
      <Container>
        <Div className="button-group">
          <Button
            type="button"
            name="EAPCET"
            onClick={handleButtonClick("EAPCET")}
          >
            EAPCET
          </Button>
          <br />
          <br />
          <Button type="button" name="ECET" onClick={handleButtonClick("ECET")}>
            ECET
          </Button>
          <br />
          <br />
          <Button type="button" name="ICET" onClick={handleButtonClick("ICET")}>
            ICET
          </Button>
          <br />
          <br />
          <Button
            type="button"
            name="PGCET"
            onClick={handleButtonClick("PGCET")}
          >
            PGCET
          </Button>
          <br />
          <br />
          <Button
            type="button"
            name="MANAGEMENT"
            onClick={handleButtonClick("MANAGEMENT")}
          >
            Management
          </Button>
        </Div>
      </Container>
    </>
  );
};

export default FormPage;
