import React, { useState } from "react";
import styles from "./footerbar.module.css"; // Update the path if necessary

const Footerbar = () => {
  const [value, setValue] = useState("");
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div style={{ marginTop: "88px" }}>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <div className={styles.firstCard}>
            <span className={styles.header}>Quick Links</span>
            <span>Home</span>
            <span>Why Choose Us</span>
            <span>Our Services </span>
            <span>Testimonials</span>
            <span>Contact Us</span>
          </div>
          <div className={styles.firstCard}>
            <span className={styles.header}>Company</span>
            <span>About Us</span>
            <span>Career</span>
            <span>Events </span>
          </div>
          <div className={styles.firstCard}>
            <span className={styles.header}>Support</span>
            <span>FAQ</span>
            <span>Email</span>
          </div>
          <div className={styles.firstCard}>
            <span className={styles.header}>Follow Us</span>
            <span>Facebook</span>
            <span>Twitter</span>
            <span>Instagram </span>
            <span>You Tube</span>
          </div>
          <div className={styles.secondCard}>
            <span className={styles.searchHeader}>
              Subscribe to Our Newsletter!
            </span>
            <div className={styles.inputContainer}>
              <input
                type="text"
                value={value}
                onChange={handleChange}
                placeholder="Your email address"
                className={styles.inputBox}
              />
              <button className={styles.subscribeButton}>Subscribe</button>
            </div>
          </div>
        </div>
        <div className={styles.copyright}>
          <span>
            © {new Date().getFullYear()} YSR Educational Services. All rights
            reserved.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footerbar;
