import React from "react";
import { Button, Typography, Box } from "@mui/material";
import location from "./Assets/Location.png";
import phoneImage from "./Assets/Phone.png";
import EmailImage from "./Assets/Email.png";
import styles from "./contactDetails.module.css";
import SendIcon from "@mui/icons-material/Send";

const ContactDetails = () => {
  return (
    <div style={{ display: "flex", marginLeft: "80px" }}>
      <Box className={styles.contactContainer}>
        <button className={styles.contactButton}>
          <span className={styles.contactButtonText}>Contact Us</span>
        </button>
        <div className={styles.contact}>
          <span className={styles.contactHeading}>Get in</span>
          <span className={styles.contactSubheading}>Touch</span>
        </div>
        <div className={styles.contactDescription}>
          <span>
            Reach out to our support team today for expert guidance and
            assistance with your educational journey.
          </span>
        </div>

        <Box className={styles.contactItem}>
          <img className={styles.locationImg} src={location} alt="Location" />
          <span className={styles.locationDiscriptions}>
            Flat no. 6-36/4, 1st floor Metro pillar no 875, Kukatpally,
            YJunction, Moosapet, Hyderabad, Telangana 500018
          </span>
        </Box>
        <Box className={styles.contactItem}>
          <img className={styles.phoneIcon} src={phoneImage} alt="Phone" />
          <span className={styles.locationDiscriptions}>+91-99596 93939</span>
        </Box>
        <Box className={styles.contactItem}>
          <img src={EmailImage} alt="Email" />
          <span className={styles.locationDiscriptions}>ysredu@gmail.com</span>
        </Box>
      </Box>
      <form className={styles.form} noValidate autoComplete="off">
        <div className={styles.gridContainer}>
          <div className={styles.gridItem}>
            <input
              required
              id="firstname"
              type="text"
              placeholder="First Name"
              className={styles.textField}
            />
          </div>
          <div className={styles.gridItem}>
            <input
              required
              id="lastname"
              type="text"
              placeholder="Last Name"
              className={styles.textField}
            />
          </div>
          <div className={styles.fullWidth}>
            <input
              required
              id="email"
              type="email"
              placeholder="Email"
              className={styles.textField}
            />
          </div>
          <div className={styles.fullWidth}>
            <input
              required
              id="phonenumber"
              type="tel"
              placeholder="Phone Number"
              className={styles.textField}
            />
          </div>
          <div className={styles.fullWidth}>
            <textarea
              required
              id="message"
              placeholder="Message"
              rows={4}
              className={styles.textField}
            />
          </div>
          <div className={styles.buttonContainer}>
            <Button
              variant="contained"
              endIcon={<SendIcon />}
              type="submit"
              className={styles.button}
              sx={{ borderRadius: "24px" }}
            >
              Send
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactDetails;
