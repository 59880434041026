import React, { useState } from "react";
import styles from "./navbar.module.css";
import logo from "../../Assets/YSRLogo.png";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className={styles.navbarStyle}>
      <div className={styles.navContainer}>
        <div className={styles.logo}>
          <img className={styles.logoImg} src={logo} alt="logo" />
          <div className={styles.CName}>
            <span>YSR EDUCATIONAL SERVICES</span>
          </div>
        </div>

        <div className={styles.navLinks}>
          <a className={styles.navLink} href="/">
            Home
          </a>
          <a className={styles.navLink} href="/our-success-story">
            About Services
          </a>
          <a className={styles.navLink} href="/why-choose-us">
            Our Partner
          </a>
          <a className={styles.navLink} href="/contact-us">
            Contact Us
          </a>
        </div>
        <div className={styles.contactButtonWrapper}>
          <button className={styles.contactButton}>
            <span>Apply Now</span>
          </button>
        </div>
        <div className={styles.menuIcon} onClick={toggleMenu}>
          ☰ {/* You can replace this with an actual icon */}
        </div>
      </div>
      <div className={`${styles.menuContent} ${menuOpen ? styles.show : ""}`}>
        <a className={styles.navLink} href="/">
          Home
        </a>
        <a className={styles.navLink} href="/why-choose-us">
          About Services
        </a>
        <a className={styles.navLink} href="#partner">
          Our Partner
        </a>
        <a className={styles.navLink} href="#contact">
          Contact Us
        </a>
        <button className={styles.contactButton}>
          <span>Apply Now</span>
        </button>
      </div>
    </div>
  );
};

export default Navbar;
