import React from "react";
import why from "./Assets/why.svg";
import circleElements from "./Assets/circle_element.svg";
import cap from "./Assets/cap.png";
import Group from "./Assets/Group.png";
import Puzzle from "./Assets/Puzzle.png";
import vector from "./Assets/Vector.png";
import styles from "./whyChooseUs.module.css";
import loveEmogi from "./Assets/love.png";
import degree from "./Assets/degree.png";
function WhyChooseUs() {
  return (
    <div style={{ height: "830px" }}>
      <div className={styles.container}>
        <div
          className={styles.left}
          style={{
            backgroundImage: `url(${circleElements})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat"
          }}
        >
          <img src={loveEmogi} alt="" className={styles.emogi1} />
          <img src={why} alt="Why Choose Us" className={styles.innerImage} />
          <img src={degree} alt="" className={styles.emoji2} />
        </div>
        <div className={styles.right}>
          <button className={styles.headingButton}>
            <span>Why Choose us</span>
          </button>
          <div>
            <sapn className={styles.heading}>
              Your Trusted Partner for Comprehensive Educational Guidance
            </sapn>
          </div>
          <div className={styles.cardContainer}>
            <div className={styles.row}>
              <div className={styles.card}>
                <img src={cap} alt="" className={styles.cardIcon} />
                <p className={styles.innerHeading}>Experienced Guidance</p>
                <p className={styles.cardContent}>
                  Established in 2009, we specialize in admissions across
                  Telangana and Andhra Pradesh, helping students secure
                  placements in over 150 institutions for MBBS, BTECH, MTECH,
                  and more.
                </p>
              </div>
              <div className={styles.card}>
                <img src={Group} alt="" className={styles.cardIcon} />
                <p className={styles.innerHeading}>Personalized Support</p>
                <p className={styles.cardContent}>
                  Personalized guidance and dedicated support throughout the
                  counseling process, ensuring access to diverse and affordable
                  educational options.
                </p>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.card}>
                <img src={Puzzle} alt="" className={styles.cardIcon} />
                <p className={styles.innerHeading}>Comprehensive Services</p>
                <p className={styles.cardContent}>
                  Expert counseling, course selection, and transparent practices
                  for successful placements and a seamless educational
                  experience.
                </p>
              </div>
              <div className={styles.card}>
                <img src={vector} alt="" className={styles.cardIcon} />
                <p className={styles.innerHeading}>Trusted Advisors</p>
                <p className={styles.cardContent}>
                  Trusted educational advisors in Telangana and Andhra Pradesh,
                  offering management quota support and handling admissions
                  without fees.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyChooseUs;
