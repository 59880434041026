import React from "react";
import doctor from "./Assets/doctor.svg";
import mca from "./Assets/mca.svg";
import mba from "./Assets/mba.svg";
import engineer from "./Assets/enginear.svg";
import styles from "./ourServices.module.css";

function OurServices() {
  return (
    <div style={{ textAlign: "center", marginTop: "80px" }}>
      <button
        style={{
          width: "180px",
          height: "43px",
          color: "rgba(5, 84, 242, 1)",
          border: "none",
          background: "rgba(232, 246, 255, 1)",
          marginBottom: "20px",
          borderRadius: "24px",
          padding: "8px 16px",
          display: "inline-block"
        }}
      >
        <span
          style={{
            fontFamily: "Poppins",
            fontSize: "18px",
            fontWeight: "700",
            lineHeight: "27px",
            textAlign: "center"
          }}
        >
          OUR SERVICES
        </span>
      </button>
      <div
        style={{
          fontFamily: "Bitter",
          fontSize: "44px",
          fontWeight: "700",
          lineHeight: "52.8px",
          textAlign: "center",
          color: "rgba(52, 52, 52, 1)",
          marginBottom: "20px"
        }}
      >
        YSR Educational Services
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "40px",
          flexWrap: "wrap",
          marginTop: "84px",
          width: "auto"
        }}
      >
        <div className={styles.hover_container}>
          <img src={doctor} alt="Doctor" className={styles.hover_image} />
          <div className={styles.hover_overlay}>
            <div className={styles.main_text}>Medical Stream</div>
            <div className={styles.additional_text}>
              We offer B.Pharmacy, M.Pharmacy, MBBS, and BDS admissions based on
              EAMCET, ECET, and PGECET exams with expert guidance.
            </div>
            <text
              className={styles.readMore}
              style={{ textDecoration: "underline" }}
            >
              Read More
            </text>
          </div>
        </div>
        <div className={styles.hover_container}>
          <img src={mca} alt="MCA" className={styles.hover_image} />
          <div className={styles.hover_overlay}>
            <div className={styles.main_text}>Engineer Stream</div>
            <div className={styles.additional_text}>
              We offer B.Pharmacy, M.Pharmacy, MBBS, and BDS admissions based on
              EAMCET, ECET, and PGECET exams with expert guidance.
            </div>
            <text
              className={styles.readMore}
              style={{ textDecoration: "underline" }}
            > 
              Read More
            </text>
          </div>
        </div>
        <div className={styles.hover_container}>
          <img src={mba} alt="MBA" className={styles.hover_image} />
          <div className={styles.hover_overlay}>
            <div className={styles.main_text}>MBA/MCA Stream</div>
            <div className={styles.additional_text}>
              We offer B.Pharmacy, M.Pharmacy, MBBS, and BDS admissions based on
              EAMCET, ECET, and PGECET exams with expert guidance.
            </div>
            <text
              className={styles.readMore}
              style={{ textDecoration: "underline" }}
            >
              Read More
            </text>
          </div>
        </div>
        <div className={styles.hover_container}>
          <img src={engineer} alt="Engineer" className={styles.hover_image} />
          <div className={styles.hover_overlay}>
            <div className={styles.main_text}>Post Graduate</div>
            <div className={styles.additional_text}>
              We offer B.Pharmacy, M.Pharmacy, MBBS, and BDS admissions based on
              EAMCET, ECET, and PGECET exams with expert guidance.
            </div>
            <text
              className={styles.readMore}
              style={{ textDecoration: "underline" }}
            >
              Read More
            </text>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurServices;
