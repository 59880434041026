import { HttpService } from "./http.service";

const accessToken = localStorage.getItem("access");
const refreshToken = localStorage.getItem("refreshToken");

const eapcetFormSubmit = async (formData) => {
  try {
    const response = await HttpService.post(
      `/api/v1/user/create-student-registration`,
      formData
    );
    return response.data;
  } catch (error) {
    throw error.response.data; 
  }
};

const ecetFormSubmit = async (formData) => {
  try {
    const response = await HttpService.post(
      `/api/v1/user/ecet-student-registration`,
      formData
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getAllEmployeesList = async () => {
  try {
    const response = await HttpService.get(
      `/api/v1/user/admin/get-all-employees`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getAllEmployees = async (formData) => {
  try {
    const response = await HttpService.get(
      `/api/v1/user/admin/get-all-employees`,
      formData
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getStudentsDetailsById = async (studentId) => {
  try {
    const response = await HttpService.get(
      `/api/v1/admin/get-student-details-by-id/${studentId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const updateEmployee = async (id, name, phoneNumber) => {
  try {
    const response = await HttpService.put(
      `/api/v1/user/admin/update-employee-by-id`,
      { id, name, phoneNumber },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const createIcetStudent = async (formData) => {
  try {
    const response = await HttpService.post(
      "/api/v1/user/admin/create-icet-students",
      formData
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
export default {
  eapcetFormSubmit,
  ecetFormSubmit,
  getAllEmployeesList,
  getAllEmployees,
  getStudentsDetailsById,
  updateEmployee,
  createIcetStudent
};
