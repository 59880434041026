import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { styled } from "@mui/system";

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: "12px",
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    maxHeight: "80vh",
    overflowY: "auto"
  }
});

const StyledDialogTitle = styled(DialogTitle)({
  fontSize: "1.5rem",
  fontWeight: "bold",
  color: "#f44336"
});

const StyledDialogContent = styled(DialogContent)({
  fontSize: "1.2rem",
  color: "#333",
  textAlign: "center",
  padding: "0 1.2rem"
});

const StyledDialogActions = styled(DialogActions)({
  display: "flex",
  justifyContent: "center",
  width: "80%"
});

const StyledButton = styled(Button)({
  backgroundColor: "#f44336",
  color: "#fff",
  fontSize: "1rem",
  padding: "0.6rem 3rem",
  borderRadius: "25px",
  minWidth: "120px",
  "&:hover": {
    backgroundColor: "#ABCCF0"
  }
});

const ErrorDialog = ({ open, message, handleClose, retry }) => {
  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton
        onClick={handleClose}
        style={{ color: "red", alignSelf: "center" }}
      >
        <CancelIcon style={{ fontSize: "6rem" }} />
      </IconButton>
      <StyledDialogTitle id="alert-dialog-title">
        {"Failed To Submit"}
      </StyledDialogTitle>
      <StyledDialogContent>
        {message || "Internal Server Error"}
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledButton onClick={retry || handleClose} autoFocus>
          <strong>Try Again</strong>
        </StyledButton>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default ErrorDialog;
